<template>
  <v-container fluid>
    <Header headerClass="valutare-aria" title="Valutare l'aria" />
    <v-container page hexagon>
      <v-row>
        <v-col class="col-md-9 col-12">
          <h2>Modelli di qualità dell'aria</h2>
          <div class="w-80">
            <p class="mt-40">
              Una sostanza inquinante immessa in atmosfera in un dato istante e
              in un determinato punto del territorio è sottoposta a
              <strong>molteplici fenomeni chimico-fisici</strong>; ad esempio il
              trasporto dovuto all'azione del vento, la dispersione per effetto
              dei moti turbolenti dei bassi strati dell'atmosfera, l'eventuale
              trasformazione chimica e la deposizione della sostanza stessa. Per
              effetto di questi fenomeni, è possibile ritrovare una data
              sostanza, dopo un tempo più o meno lungo, in altri punti del
              territorio e con diversi valori di concentrazione.
            </p>
          </div>
          <v-card flat>
            <v-list-item three-line class="center">
              <v-list-item-content>
                <p>
                  Obiettivo dei modelli di simulazione degli inquinanti in
                  atmosfera è proprio la
                  <strong>
                    valutazione dei valori assunti dalla concentrazione di una
                    certa sostanza inquinante in tutti i punti dello spazio e in
                    diversi momenti.
                  </strong>
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-card class="card-cont-img-behind-right modelli" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <p>
                  La Regione Piemonte ha sviluppato e realizzato, presso ARPA
                  Piemonte, una
                  <strong
                    >catena modellistica operativa di qualità dell’aria</strong
                  >, basata sull’applicazione di modelli euleriani di chimica e
                  trasporto (i modelli C.T.M., Chemical Transport Models).<br />
                  Si tratta dei modelli più idonei (come indicato nell’appendice
                  III del D.Lgs. n. 155/10) per un contesto, quale quello
                  piemontese, caratterizzato da un’elevata complessità
                  morfologica ed emissiva, su scale spaziali che vanno da quella
                  urbana a quella regionale e di bacino, su scale temporali sia
                  orarie sia di lungo periodo.<br />
                  Questo sistema è in grado di
                  <strong>
                    riprodurre la complessa serie di reazioni chimiche che
                    avvengono in atmosfera e simulare le concentrazioni dei
                    principali inquinanti
                  </strong>
                  atmosferici su tutto il territorio regionale.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div class="w-80">
            <p class="pt-40">
              In generale, per funzionare correttamente, un modello deve
              disporre di informazioni di ingresso quali:
            </p>
            <div class="lista">
              <ul>
                <li>
                  schematizzazione fisico-chimica-matematica o statistica dei
                  fenomeni di dispersione, trasformazione e deposizione
                </li>
                <li>
                  caratterizzazione del territorio in esame (orografia,
                  tipologia di uso del suolo)
                </li>
                <li>
                  descrizione dei venti e della turbolenza atmosferica
                </li>
                <li>
                  caratterizzazione delle fonti emissive.
                </li>
              </ul>
            </div>
          </div>
        </v-col>
        <v-col class="col-md-3 col-12 col-dx">
          <MenuValutareAria page="modelli-qualita" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Header from "@/components/layout/Header";
import MenuValutareAria from "@/views/valutare-aria/MenuValutareAria";

export default {
  components: {
    Header,
    MenuValutareAria
  }
};
</script>
