<template>
  <div :class="{ menu_fixed: scrolled }">
    <div>
      <h3>VALUTARE L'ARIA</h3>
      <v-list class="menu-dx">
        <v-list-item>
          <router-link :to="{ name: 'sistema' }">
            Sistema regionale
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'emissioni' }">
            Inventario emissioni
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'modelli' }">
            Modelli di qualità dell'aria
          </router-link>
        </v-list-item>
        <v-list-item>
          <router-link :to="{ name: 'monitoraggio' }">
            Rete di monitoraggio
          </router-link>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'sistema-regionale'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/valutare-aria/modelli"> Modelli di qualità dell'aria </a-->
          <router-link :to="{ name: 'modelli' }">
            Modelli di qualità dell'aria
          </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.sistemapiemonte.it/cms/privati/ambiente-e-energia/servizi/474-irea-inventario-regionale-delle-emissioni-in-atmosfera"
            target="_blank"
          >
            IREA Inventario Emissioni
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="http://www.cittametropolitana.torino.it/cms/ambiente/qualita-aria/dati-qualita-aria/ipqa"
            target="_blank"
          >
            Indice previsionale di qualità dell'aria
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="http://www.regione.piemonte.it/governo/bollettino/abbonati/2020/03/siste/00000074.htm"
            target="_blank"
          >
            Programma di valutazione
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/modellistica/attivita-servizi-e-controlli-di-arpa-piemonte-1/attivita-servizi-e-controlli-di-arpa-piemonte"
            target="_blank"
          >
            Attività di ARPA
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'inventario-emissioni'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/qualita-aria/fonti"> Fonti emissive </a-->
          <router-link :to="{ name: 'fonti' }"> Fonti emissive </router-link>
        </v-list-item>
      </v-list>
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.sistemapiemonte.it/cms/privati/ambiente-e-energia/servizi/474-irea-inventario-regionale-delle-emissioni-in-atmosfera"
            target="_blank"
          >
            IREA Inventario Emissioni
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'modelli-qualita'">
      <h3 class="mt-5 mb-0">APPROFONDIMENTI ESTERNI</h3>
      <v-list>
        <v-list-item>
          <a
            href="http://www.sistemapiemonte.it/cms/privati/ambiente-e-energia/servizi/474-irea-inventario-regionale-delle-emissioni-in-atmosfera"
            target="_blank"
          >
            IREA Inventario Emissioni
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://www.arpa.piemonte.it/approfondimenti/temi-ambientali/aria/modellistica/modellistica"
            target="_blank"
          >
            ARPA Modellistica
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://webgis.arpa.piemonte.it/aria_modellistica_webapp/index.html"
            target="_blank"
          >
            Valutazioni annuali qualità aria
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://fairmode.jrc.ec.europa.eu/" target="_blank">
            EU - FAIRMODE
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a
            href="https://atmosphere.copernicus.eu/about-us?q=about-cams"
            target="_blank"
          >
            EU - COPERNICUS
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
        <v-list-item>
          <a href="https://impatti.sostenibilita.enea.it/" target="_blank">
            ENEA
            <i class="v-icon notranslate mdi mdi-launch"></i>
          </a>
        </v-list-item>
      </v-list>
    </div>
    <div v-if="page == 'rete-monitoraggio'">
      <h3 class="mt-5 mb-0">VEDI ANCHE</h3>
      <v-list class="pt-0">
        <v-list-item>
          <!--a href="/qualita-aria/dati"> Accesso ai dati misurati </a-->
          <router-link :to="{ name: 'dati-qualita-aria' }">
            Accesso ai dati misurati
          </router-link>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  props: ["page"],
  data() {
    return {
      scrolled: false
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>
